import Image from 'next/image';
import React from 'react';
import Masonry from 'react-masonry-css';

type ImageGridProps = {
  images: string[];
};

const ImageGrid = ({ images }: ImageGridProps) => {
  const breakpointColumnsObj = {
    default: 6,
    1280: 4,
    1024: 3,
    768: 3,
    640: 3
  };

  return (
    <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
      {images.map((image, index) => (
        <div key={index} className="mb-4">
          <Image
            width={1000}
            height={1000}
            src={image}
            alt="PicStudio.AI Portrait"
            className="w-full h-auto object-cover"
          />
        </div>
      ))}
    </Masonry>
  );
};

export default ImageGrid;

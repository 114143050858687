import { Svg } from '../../_design_system';
import { ComponentProps } from 'react';
import classNames from 'classnames';
import { AiFillStar } from 'react-icons/ai';

interface NumberOneProps extends ComponentProps<typeof Svg> {
  className?: string;
}

const NumberOne = <T extends NumberOneProps>({ className, ...props }: T) => {
  const { height, width } = props;

  const logoClasses = classNames('fill-primary', 'w-6', className); // Add 'w-36' as the default width class

  // Use the raw width and height. Class above is to define width when used
  const logoHeight = height || 802;
  const logoWidth = width || 416;

  return (
    <div className="flex justify-center items-center">
      <Svg viewBox={`0 0 ${logoWidth} ${logoHeight}`} fill="" stroke="none" {...props} className={logoClasses}>
        <path d="m334.46 49.002c-18.278 12.277-38.479 27.48-54.76 44.437-28.887 30.086-57.536 64.004-82.159 97.941-16.634 22.926-38.215 57.715-50.006 83.305-15.691 34.056-24.985 57.915-33.597 95.332-12.132 52.713-10.459 106.81 1.863 157.81 18.129 75.031 76.15 137.81 134.13 188.77 45.358 39.863 106.34 65.942 159.92 85.098l5.536-14.63c-53.09-18.97-109.36-44.31-152.83-82.56-55.92-49.21-107.57-109.22-129.85-180.73-13.57-43.53-16.72-91.29-9.6-137.54 6.238-40.516 21.918-81.121 37.193-118.04 12.558-30.349 27.2-53.991 45.06-78.892 22.645-31.574 47.529-62.365 73.981-90.48 17.123-18.199 40.023-34.629 58.415-46.982l-3.296-2.839z" />
        <path d="m311.24 756.98s-37.634-11.898-67.5-8.393c-47.415 5.564-51.786 35-51.786 35s19.022 11.809 59.821 7.321c47.796-5.257 59.464-33.929 59.464-33.929l1e-3 1e-3z" />
        <path d="m247.15 713.02s-31.716-15.044-61.786-14.821c-52.058 0.385-55.179 28.036-55.179 28.036s18.843 13.595 60.536 13.036c48.08-0.645 56.429-26.251 56.429-26.251z" />
        <path d="m199.74 666.79s-30.157-26.43-59.326-33.739c-50.498-12.653-67.224 16.73-67.224 16.73s18.771 22.52 59.276 32.411c46.711 11.406 67.273-15.402 67.273-15.402z" />
        <path d="m146.2 595.58s-25.79-27.185-61.094-38.032c-46.073-14.156-67.729 13.636-68.361 14.331 0 0 18.879 23.762 58.267 34.179 52.832 13.973 71.188-10.478 71.188-10.478z" />
        <path d="m120.7 538.91s-24.019-36.576-59.844-53.84c-34.801-16.78-60.225-2.49-60.856-1.93 0 0 17.877 32.67 50.767 48.325 44.687 21.271 69.938 7.441 69.938 7.441l-5e-3 4e-3z" />
        <path d="m112.96 489.18c-12.82-12.06-13.775-44.84-32.099-71.44-25.563-37.11-67.73-34.42-68.361-34.07 0 0 4.5142 46.234 36.481 74.178 33.973 29.697 63.978 31.335 63.978 31.335l1e-3 -3e-3z" />
        <path d="m109.42 411.25s1.244-40.185-9.353-70.701c-14.783-42.569-55.138-50.926-55.839-50.759 0 0-6.5444 45.519 16.848 80.952 24.861 37.657 48.344 40.507 48.344 40.507v1e-3z" />
        <path d="m126.51 335.94s8.049-33.735 3.971-65.78c-6.146-48.297-43.73-58.521-44.448-58.451 0 0-12.884 44.733 5.4449 83.031 19.479 40.702 35.032 41.2 35.032 41.2z" />
        <path d="m159.62 255.08s13.089-30.516 11.412-62.775c-2.328-44.771-32.665-63.685-33.385-63.711 0 0-29.251 26.374-12.451 83.145 10.191 34.437 34.425 43.342 34.425 43.342l-1e-3 -1e-3z" />
        <path d="m201.74 188.6s19.451-29.962 24.832-61.814c5.052-29.903-7.08-62.481-9.788-65.475 0 0-31.65 13.91-30.465 67.294 0.797 35.905 15.421 59.995 15.421 59.995z" />
        <path d="m253.49 124.45s23.192-24.697 29.233-58.466c5-27.984-0.04-58.394-5.31-65.984 0 0-30.25 18.795-32.448 66.327-1.659 35.875 6.126 52.95 8.525 58.127v-4e-3z" />
        <path d="m291.03 85.107s44.199 1.5969 66.166-11.157c24.589-14.276 48.719-45.263 50.623-54.309 0 0-38.712-16.975-74.806 14.032-27.242 23.402-39.862 46.137-41.983 51.434z" />
        <path d="m242.33 138.43s21.141-24.752 57.469-31.147c28.002-4.928 49.529-0.378 63.35 7.676 0 0-21.322 27.318-62.19 31.084-35.762 3.294-53.376-5.386-58.63-7.613h1e-3z" />
        <path d="m198.64 195.5s20.13-23.742 56.459-30.136c28.002-4.929 41.448 0.127 55.269 8.18 0 0-17.029 26.056-57.897 29.821-35.762 3.295-48.578-5.638-53.832-7.865h1e-3z" />
        <path d="m156.23 269.95s18.805-29.262 52.808-43.562c34.843-14.654 52.739-7.657 67.725-2.062-4.779 7.168-24.337 37.05-56.046 46.02-34.557 9.776-58.932 0.909-64.487-0.396z" />
        <path d="m131.06 336.07s16.664-30.532 49.556-47.228c33.706-17.109 52.056-11.41 67.404-6.902-4.254 7.492-21.624 38.696-52.61 49.912-33.77 12.223-58.716 5.122-64.35 4.218z" />
        <path d="m117.84 414.65c14.421-18.489 22.261-38.366 44.449-58.149 27.336-24.373 50.792-16.603 66.711-15.029-7.837 14.719-19.603 38.003-43.92 55.546-29.126 21.011-61.536 17.473-67.24 17.633v-1e-3z" />
        <path d="m119.63 487.05c12.262-19.986 18.081-35.565 37.916-57.707 24.437-27.278 47.605-22.435 63.6-22.652-6.141 15.503-16.49 38.695-38.692 58.849-26.592 24.138-57.175 20.713-62.824 21.51z" />
        <path d="m135.4 544.78c18.448-28.481 16.12-34.927 34.176-58.542 19.392-25.362 43.885-24.665 59.811-26.165-4.877 15.946-12.165 37.517-32.837 59.237-22.045 23.163-44.975 20.181-61.149 25.47h-1e-3z" />
        <path d="m165.71 601.35c11.63-25.198 9.622-40.933 20.286-58.794 17.442-29.213 34.541-30.474 50.467-31.973-12.703 36.396-9.762 36.564-23.746 58.732-13.607 21.571-32.096 24.474-47.007 32.036v-1e-3z" />
        <path d="m202.25 648.92c6.628-26.95-0.102-35.197 6.973-54.76 11.571-31.996 28.119-36.484 43.469-40.983-1.765 21.479 0.9 35.106-11.139 58.387-8.502 16.44-26.101 27.098-39.303 37.356z" />
        <path d="m275.9 596.01s13.293 25.664 8.877 55.408c-6.256 42.144-28.777 48.458-28.777 48.458s-12.827-19.155-11.523-58.022c1.392-41.516 31.423-45.845 31.423-45.845v1e-3z" />
        <path d="m323.88 638.94s13.303 25.984 10.393 55.914c-3.73 38.356-24.736 47.953-24.736 47.953s-13.232-21.412-13.796-53.728c-0.86-49.346 28.14-50.138 28.14-50.138l-1e-3 -1e-3z" />
      </Svg>
      <div className="flex flex-col items-center justify-center">
        <div className="font-bold text-sm">#1 AI Portrait App</div>
        <div className="flex">
          <AiFillStar className="text-primary" size={12} />
          <AiFillStar className="text-primary" size={12} />
          <AiFillStar className="text-primary" size={12} />
          <AiFillStar className="text-primary" size={12} />
          <AiFillStar className="text-primary" size={12} />
        </div>
      </div>
      <Svg viewBox={`0 0 ${logoWidth} ${logoHeight}`} fill="" stroke="none" {...props} className={logoClasses}>
        <path d="m80.928 49.002c18.278 12.277 38.479 27.48 54.76 44.437 28.887 30.086 57.536 64.004 82.159 97.941 16.634 22.926 38.215 57.715 50.006 83.305 15.691 34.056 24.985 57.915 33.597 95.332 12.132 52.713 10.459 106.81-1.863 157.81-18.129 75.031-76.15 137.81-134.13 188.77-45.358 39.863-106.34 65.942-159.92 85.098l-5.5363-14.63c53.09-18.97 109.36-44.31 152.83-82.56 55.92-49.21 107.57-109.22 129.85-180.73 13.57-43.53 16.72-91.29 9.6-137.54-6.237-40.516-21.918-81.121-37.193-118.04-12.558-30.349-27.2-53.991-45.06-78.892-22.645-31.574-47.528-62.365-73.98-90.48-17.123-18.199-40.024-34.629-58.416-46.982l3.2963-2.839z" />
        <path d="m104.15 756.98s37.634-11.898 67.5-8.393c47.415 5.564 51.786 35 51.786 35s-19.022 11.809-59.821 7.321c-47.796-5.257-59.464-33.929-59.464-33.929l-1e-3 1e-3z" />
        <path d="m168.24 713.02s31.716-15.044 61.786-14.821c52.058 0.385 55.179 28.036 55.179 28.036s-18.843 13.595-60.536 13.036c-48.08-0.645-56.429-26.251-56.429-26.251z" />
        <path d="m215.65 666.79s30.157-26.43 59.326-33.739c50.498-12.653 67.224 16.73 67.224 16.73s-18.771 22.52-59.277 32.411c-46.711 11.406-67.273-15.402-67.273-15.402z" />
        <path d="m269.19 595.58s25.79-27.185 61.094-38.032c46.073-14.156 67.729 13.636 68.361 14.331 0 0-18.879 23.762-58.267 34.179-52.832 13.973-71.188-10.478-71.188-10.478z" />
        <path d="m294.69 538.91s24.019-36.576 59.844-53.84c34.801-16.78 60.225-2.49 60.856-1.93 0 0-17.877 32.67-50.767 48.325-44.687 21.271-69.938 7.441-69.938 7.441l5e-3 4e-3z" />
        <path d="m302.43 489.18c12.82-12.06 13.775-44.84 32.099-71.44 25.563-37.11 67.73-34.42 68.361-34.07 0 0-4.515 46.234-36.481 74.178-33.973 29.697-63.978 31.335-63.978 31.335l-1e-3 -3e-3z" />
        <path d="m305.97 411.25s-1.244-40.185 9.353-70.701c14.783-42.569 55.138-50.926 55.839-50.759 0 0 6.544 45.519-16.848 80.952-24.861 37.657-48.344 40.507-48.344 40.507v1e-3z" />
        <path d="m288.88 335.94s-8.049-33.735-3.972-65.78c6.147-48.297 43.73-58.521 44.448-58.451 0 0 12.884 44.733-5.444 83.031-19.479 40.702-35.032 41.2-35.032 41.2z" />
        <path d="m255.77 255.08s-13.089-30.516-11.412-62.775c2.328-44.771 32.665-63.685 33.385-63.711 0 0 29.251 26.374 12.451 83.145-10.191 34.437-34.425 43.342-34.425 43.342l1e-3 -1e-3z" />
        <path d="m213.65 188.6s-19.451-29.962-24.832-61.814c-5.052-29.903 7.08-62.481 9.788-65.475 0 0 31.65 13.91 30.465 67.294-0.797 35.905-15.421 59.995-15.421 59.995z" />
        <path d="m161.9 124.45s-23.192-24.697-29.233-58.466c-5-27.984 0.04-58.394 5.31-65.984 0 0 30.25 18.795 32.448 66.327 1.658 35.875-6.127 52.95-8.526 58.127l1e-3 -4e-3z" />
        <path d="m124.36 85.107s-44.199 1.5969-66.166-11.157c-24.589-14.276-48.719-45.263-50.623-54.309 0 0 38.712-16.975 74.806 14.032 27.242 23.402 39.862 46.137 41.983 51.434z" />
        <path d="m173.06 138.43s-21.141-24.752-57.469-31.147c-28.002-4.928-49.53-0.378-63.35 7.676 0 0 21.322 27.318 62.19 31.084 35.762 3.294 53.376-5.386 58.63-7.613h-1e-3z" />
        <path d="m216.75 195.5s-20.13-23.742-56.459-30.136c-28.002-4.929-41.448 0.127-55.269 8.18 0 0 17.029 26.056 57.897 29.821 35.762 3.295 48.578-5.638 53.832-7.865h-1e-3z" />
        <path d="m259.16 269.95s-18.805-29.262-52.808-43.562c-34.843-14.654-52.739-7.657-67.725-2.062 4.779 7.168 24.337 37.05 56.046 46.02 34.557 9.776 58.932 0.909 64.487-0.396z" />
        <path d="m284.33 336.07s-16.664-30.532-49.556-47.228c-33.706-17.109-52.056-11.41-67.404-6.902 4.254 7.492 21.624 38.696 52.61 49.912 33.77 12.223 58.716 5.122 64.35 4.218z" />
        <path d="m297.55 414.65c-14.421-18.489-22.261-38.366-44.449-58.149-27.336-24.373-50.792-16.603-66.711-15.029 7.836 14.719 19.603 38.003 43.92 55.546 29.126 21.011 61.536 17.473 67.24 17.633v-1e-3z" />
        <path d="m295.76 487.05c-12.262-19.986-18.081-35.565-37.916-57.707-24.437-27.278-47.605-22.435-63.6-22.652 6.14 15.503 16.49 38.695 38.692 58.849 26.592 24.138 57.175 20.713 62.824 21.51z" />
        <path d="m279.99 544.78c-18.448-28.481-16.12-34.927-34.176-58.542-19.392-25.362-43.885-24.665-59.811-26.165 4.877 15.946 12.165 37.517 32.837 59.237 22.045 23.163 44.975 20.181 61.149 25.47h1e-3z" />
        <path d="m249.68 601.35c-11.63-25.198-9.622-40.933-20.286-58.794-17.442-29.213-34.541-30.474-50.467-31.973 12.703 36.396 9.762 36.564 23.746 58.732 13.607 21.571 32.096 24.474 47.007 32.036v-1e-3z" />
        <path d="m213.14 648.92c-6.628-26.95 0.102-35.197-6.973-54.76-11.571-31.996-28.119-36.484-43.469-40.983 1.765 21.479-0.9 35.106 11.139 58.387 8.501 16.44 26.101 27.098 39.303 37.356z" />
        <path d="m139.49 596.01s-13.293 25.664-8.877 55.408c6.256 42.144 28.777 48.458 28.777 48.458s12.827-19.155 11.523-58.022c-1.393-41.516-31.423-45.845-31.423-45.845v1e-3z" />
        <path d="m91.508 638.94s-13.303 25.984-10.393 55.914c3.7305 38.356 24.736 47.953 24.736 47.953s13.232-21.412 13.796-53.728c0.861-49.346-28.14-50.138-28.14-50.138l1e-3 -1e-3z" />
      </Svg>
    </div>
  );
};

export default NumberOne;

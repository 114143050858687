// pages/marketing.js
import ImageGrid from '@components/ImageGrid/ImageGrid';

export default function PhotoGallery() {
  const images = [
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/ac4c5b35-cc8e-4c23-7a94-6aef50852100/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/722f2070-27c0-4e2b-0caf-7f0ca1f79b00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b7294d8f-df01-4dc2-156a-2a775a52bd00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/c6740020-4bf0-4d89-8aea-85cfa2634700/fit=crop,h=256,w=256',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/4fb07735-d610-4580-4660-b5d4b6695d00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/99ff6fc5-2463-4f51-011f-0b129b283e00/fit=crop,h=256,w=256',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/c0606d67-d5d0-4511-e512-25e7dc408900/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/9f3bc77f-721e-4bd9-223f-b3331021fd00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/84344abe-ca43-4902-2835-a34c21ef6400/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b8700e40-31f3-4298-dda0-a8bd62a9a400/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/ffcaadd4-82e1-4727-ab6e-62d5a2ed2000/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/7c41c3bf-3b8e-4f98-277a-a6020bcfc600/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/6511568f-b0f5-4764-10a8-5e247488e700/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/4ca94c5d-f4b3-4b94-d298-83e6c75d2e00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b7d454be-9382-4170-8d20-05cf3f4fbd00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/cd4f3fd9-8fb1-4de6-4f3f-229814654000/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/7ce13e50-7863-419c-32d4-124cca55aa00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/6fd20dc4-1e47-4e71-29a4-af60efddd700/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/895aa4d5-b961-409c-5e3b-1e206414e100/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b8e56a34-cab7-48d0-f6e2-66d6821d1d00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/fcbecb2a-f95f-4362-b7c7-54855a25f100/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/c840f203-64ba-443f-2ea2-793fa7766600/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/e4dc91e2-9875-4201-2727-92c27dd43f00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/a8b7e380-9280-431a-918e-4f62eab58e00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/52506df8-2947-466e-a894-6b854103db00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3430ddac-2b75-4a26-d9aa-7ffad5f83700/public'
  ];

  return (
    <div className="w-[calc(100vw-36px)] mx-auto left-1/2 -translate-x-1/2 relative">
      <div className="absolute bg-gradient-to-b from-background to-background/0 top-0 w-full h-1/5" />
      <ImageGrid images={images} />
    </div>
  );
}

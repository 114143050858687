import { AiFillStar } from 'react-icons/ai';
import { BsLinkedin, BsFacebook, BsInstagram } from 'react-icons/bs';

const SocialProof = () => {
  return (
    <div className="w-full max-w-4xl mt-10 mx-auto mb-0 bg-red-500/0 flex flex-col md:flex-row justify-center gap-4">
      <div className="flex flex-col justify-center items-center bg-muted w-full md:w-1/2 p-6 rounded-lg">
        <h2 className="text-center text-base font-semibold leading-8 text-primary">3M+ photos generated</h2>
        <div className="flex mb-3">
          <AiFillStar className="text-amber-500" size={24} />
          <AiFillStar className="text-amber-500" size={24} />
          <AiFillStar className="text-amber-500" size={24} />
          <AiFillStar className="text-amber-500" size={24} />
          <AiFillStar className="text-amber-500" size={24} />
        </div>

        <div className="flex -space-x-2 overflow-hidden">
          <img
            className="inline-block h-10 w-10 rounded-full ring-4 ring-muted"
            src="https://pbs.twimg.com/profile_images/1691245472894373888/SyZVf8yD_400x400.jpg"
            alt=""
          />
          <img
            className="inline-block h-10 w-10 rounded-full ring-4 ring-muted"
            src="https://storage.prompt-hunt.workers.dev/0fbbc50f-f824-49da-8f33-86c23cfa1cdc"
            alt=""
          />
          <img
            className="inline-block h-10 w-10 rounded-full ring-4 ring-muted"
            src="https://storage.prompt-hunt.workers.dev/0e215b6b-3b00-4ba1-835a-309ec76cf538"
            alt=""
          />
          <img
            className="inline-block h-10 w-10 rounded-full ring-4 ring-muted"
            src="https://storage.prompt-hunt.workers.dev/4e4ddd7d-e997-48fd-9182-7048db2262ae"
            alt=""
          />
        </div>
      </div>
      <div className="bg-muted w-full md:w-1/2 p-6 rounded-lg">
        <h2 className="text-center text-base font-semibold leading-8 text-primary">Perfect to use on</h2>
        <div className="mx-auto grid grid-cols-3 w-fit gap-4 text-xs font-normal">
          <div className="p-2 flex flex-col gap-2 items-center">
            <BsLinkedin size={40} className="w-full object-contain col-span-1" />
            <div>LinkedIn</div>
          </div>
          <div className="p-2 flex flex-col gap-2 items-center">
            <BsFacebook size={40} className="w-full object-contain col-span-1" />
            <div>Facebook</div>
          </div>
          <div className="p-2 flex flex-col gap-2 items-center">
            <BsInstagram size={40} className="w-full object-contain col-span-1" />
            <div>Instagram</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialProof;

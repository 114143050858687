import { useState, useEffect } from 'react';
import Image from 'next/image';

export default function OriginalVsGenerated() {
  const showcaseSlideshow = [
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/e1d5707e-2f39-47c6-a12f-c47ad014d200/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/6511568f-b0f5-4764-10a8-5e247488e700/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b8700e40-31f3-4298-dda0-a8bd62a9a400/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/2ec14dae-ce72-4ab6-6a44-56e2cccd0800/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3430ddac-2b75-4a26-d9aa-7ffad5f83700/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/4ca94c5d-f4b3-4b94-d298-83e6c75d2e00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/2f92c860-20b4-4107-16de-e6ab0c582a00/public'
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % showcaseSlideshow.length);
    }, 1500); // Change image every 4 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [showcaseSlideshow.length]);

  return (
    <div className="flex items-center max-w-md overflow-clip text-white text-xs md:text-lg gap-4">
      <div className="w-1/2 relative">
        <div className="bg-neutral-600 mix-blend-multiply px-4 py-2 absolute bottom-4 left-1/2 transform -translate-x-1/2 flex w-fit rounded-full">
          Original
        </div>
        <div className="px-4 py-2 absolute bottom-4 left-1/2 transform -translate-x-1/2 flex w-fit rounded-full z-20 text-white/75">
          Original
        </div>
        <Image
          src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/14fe1793-6626-4e6a-f01e-66ed973f9c00/public'}
          width={1000}
          height={1000}
          alt="Selfies"
          className="object-cover h-fit aspect-[3/5] rounded-lg md:rounded-3xl"
        />
      </div>
      <div className="w-1/2 relative pt-6">
        <div className="bg-neutral-800 mix-blend-multiply px-4 py-2 absolute bottom-4 left-1/2 transform -translate-x-1/2 flex w-fit rounded-full  whitespace-nowrap text-white/0 font-bold">
          AI Generated
        </div>
        <div className="px-4 py-2 absolute bottom-4 left-1/2 transform -translate-x-1/2 flex w-fit rounded-full z-20  whitespace-nowrap text-amber-400 font-bold">
          <span className="bg-gradient-to-r from-[#BE22FF] via-[#FF328F] to-[#FF981F] text-transparent bg-clip-text">
            AI Generated
          </span>
        </div>
        <Image
          src={showcaseSlideshow[currentIndex]}
          width={1000}
          height={1000}
          alt="Selfies"
          className="object-cover h-fit aspect-[3/5] rounded-lg md:rounded-3xl"
        />
      </div>
    </div>
  );
}

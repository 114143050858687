import * as React from 'react';
import { Button } from '@components/ui/button';
import { PlayCircleIcon } from '@heroicons/react/20/solid';
import { Dialog, DialogContent, DialogTrigger } from '@components/ui/dialog';

function PicStudioVideoSep2023() {
  return (
    <>
      {/* <AnimatePresence>
        {showVideoModal && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="w-screen h-screen bg-black/80 fixed left-0 top-0 z-40"
              onClick={handleClick}
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-black w-screen md:w-[90vw] aspect-[16/9] max-h-[90vh] fixed left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 z-50 shadow-2xl"
            >
              <div className="h-full w-full">
                <div className="w-full h-full"></div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence> */}
      <Dialog>
        <DialogTrigger asChild>
          <Button className="w-full lg:w-fit" variant={'outline'} size={'xl'}>
            <PlayCircleIcon className="w-6 mr-2" /> Watch video
          </Button>
        </DialogTrigger>
        <DialogContent className="p-0 aspect-[9/16] border-none">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/Y4QELM9gZN8?controls=0&autoplay=1&modestbranding=1&playsinline=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PicStudioVideoSep2023;
